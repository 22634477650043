import React from 'react';
import PropTypes from 'prop-types';
import './disclaimer-message.scss';

export const DisclaimerMessageContent = ({ is3M }) => {
  const commonText = `${is3M ? '3M personal' : 'Personal'} protective equipment sold through The Home Depot is only intended for US 
  occupational workplace use. ${is3M ? 'This 3M personal' : 'Personal'} protective equipment must be used in compliance 
  with the Occupational Safety and Health Administration (OSHA) Personal Protective Equipment (PPE) standard 
  (29 CFR 1910.132) and all other applicable health and safety standards, as well as all user instructions, 
  warnings and limitations accompanying each product. It is essential that all product user instructions and 
  government regulations on the use of each product be followed in order for the product to help protect the 
  wearer. Misuse of personal protective equipment may result in injury, sickness, or death. For correct product 
  selection and use, individuals should consult their on-site safety professional or industrial hygienist.`;

  const additionalInfoText = is3M
    ? 'For additional product information, visit'
    : 'For additional PPE information, visit';

  const additionalInfoUrl = is3M
    ? 'https://www.3m.com/3M/en_US/worker-health-safety-us/'
    : 'https://www.osha.gov/personal-protective-equipment';

  const additionalInfoLinkText = is3M
    ? 'www.3M.com/PPESafety'
    : 'https://www.osha.gov/personal-protective-equipment';

  return (
    <>
      <div className="legal-disclaimer-message__overlay" data-component="DisclaimerMessageOverlayContent">
        <p>
          {commonText}
        </p><br />
        <div>
          <span>
            {additionalInfoText}
            <a
              className="u__default-link"
              rel="noreferrer"
              href={additionalInfoUrl}
              target="_blank"
            > {additionalInfoLinkText}.
            </a>
          </span>
        </div>
      </div>
      <hr />
    </>
  );
};

DisclaimerMessageContent.displayName = 'DisclaimerMessage';

DisclaimerMessageContent.propTypes = {
  is3M: PropTypes.bool
};

DisclaimerMessageContent.defaultProps = {
  is3M: false
};
